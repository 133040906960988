/* Global styles */
* {
  box-sizing: border-box;
  font-family: 'Open Sans';
}
body {
  margin: 0;
}
a {
  color: #070707;
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}

/* Header */
.header-container {
  transition: transform 0.3s ease;
  background-color: #ffb505;
  border-bottom: 1px solid #fcfdfa;
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.header-container.hidden {
  transform: translateY(-100%);
  transition: transform 0.5s ease;
}

.logo {
  width: 20%; /* Adjust size as needed */
}

.logo img {
  padding-top: 2.5vh;
  width: 100%;
  height: auto;
  object-fit: contain; /* Ensure the logo image fits inside the container */
}

.navigation-container ul {
  display: flex;
}
.navigation-container li {
  margin-left: 20px;
}
.navigation-container p {
  font-size: 18px;
  font-family: Helvetica;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  color: #fcfdfa;
}
.navigation-container p::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #006e90;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
  transform-origin: left;
}

.navigation-container p:hover::after {
  transform: scaleX(1);
}

@media screen and (max-width: 768px) {
  .header-container {
    transform: translateY(-100%); /* Move the navbar out of view on mobile screens */
  }
}

/* Navigation2 -smaller screen size */
.navigation2 {
  display: none; /* Hide by default */
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Set background color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navigation2.visible {
  display: flex; /* Show when visible class is applied */
  place-content: center;
  width: 100%;
}

.navigation2 a {
  text-decoration: none;
  color: #555;
  margin: 0 10px;
}

.navigation2 a p {
  font-size: 1.3rem;
  margin: 0;
}

.nav2-separator {
  border-left: 1px solid #ffb505;/* Align the separator to the top of the menu item */
  height: 30px;
}

@media screen and (max-width: 768px) and (min-width: 600px)  {
  .navigation2 {
    top: 54%;
  }
}

@media screen and (max-width: 600){
  .navigation2 {
    top: 40%;
  }
}

@media screen and (max-width: 500px) {
  .navigation2 {
    top: 30%;
  }
}

/* Hero section */
.hero {
  position: relative;
  height: 120vh;
  overflow: hidden;
  margin-bottom: 10vh;
}

.hero-video {
  width: 100%; /* Use percentage-based width */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure video doesn't exceed container's width */
  position: absolute;
  top: -10vh;
  left: 0;
}
@media screen and (max-width: 1200px) {
  .hero {
    margin-bottom: -35vh;
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .hero-video {
    /* Adjust size for smaller screens */
    height: auto;
    width: 100%;
  }

}

.hero-content {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fcfdfa;
  opacity: 1; /* Initial opacity */
  transition: opacity 1s ease-out; /* Add a transition for smooth easing */
}

.fade-out {
  opacity: 0; /* Set opacity to 0 to fade out */
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2rem;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .hero-video {
    left: 50%; /* Move the video to the center horizontally */
    transform: translateX(-50%); /* Adjust horizontal position */
  }
  .hero-content {
    top: 17vh;
    }

  .hero {
    margin-bottom: -86vh;
  }
}

@media screen and (max-width: 768px) {
  .hero-content {
    top: 17vh;
    }

  .hero {
    margin-bottom: -80vh;
  }

  .hero h1 {
    font-size: 1.7rem;
  }

  .hero p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .hero-content {
    top: 10vh;
  }

  .hero {
    margin-bottom: -90vh;
  }

  .hero h1 {
    font-size: 1.2rem;
  }

  .hero p {
    display: none;
  }
}

/* Divider */
.divider {								/* minor cosmetics */
  display: table; 
  font-size: 24px; 
  text-align: center; 
  width: 75%; 						/* divider width */
  margin: 40px auto;					/* spacing above/below */
}
.divider span { display: table-cell; position: relative; }
.divider span:first-child, .divider span:last-child {
  width: 50%;
  top: 13px;							/* adjust vertical align */
  -moz-background-size: 100% 2px; 	/* line width */
  background-size: 100% 2px; 			/* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.divider span:first-child {				/* color changes in here */
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#ffb505));
  background-image: -webkit-linear-gradient(180deg, transparent, #ffb505);
  background-image: -moz-linear-gradient(180deg, transparent, #ffb505);
  background-image: -o-linear-gradient(180deg, transparent, #ffb505);
  background-image: linear-gradient(90deg, transparent, #ffb505);
}
.divider span:nth-child(2) {
  color: #006e90; padding: 0px 5px; width: auto; white-space: nowrap;
}
.divider span:last-child {				/* color changes in here */
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffb505), to(transparent));
  background-image: -webkit-linear-gradient(180deg, #ffb505, transparent);
  background-image: -moz-linear-gradient(180deg, #ffb505, transparent);
  background-image: -o-linear-gradient(180deg, #ffb505, transparent);
  background-image: linear-gradient(90deg, #ffb505, transparent);
}

/* Phuket section */
.phuket-title {
  color: #ffb505;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.phuket-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 20px;
}

.phuket-card {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 8px;
  transition: transform 0.3s;
}

.phuket-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.phuket-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  color: #fcfdfa;
  opacity: 0;
  transition: opacity 0.3s;
}

.phuket-card:hover {
  transform: scale(1.05);
}

.phuket-card:hover .phuket-card-overlay {
  opacity: 1;
}

.phuket-card-overlay p {
  font-size: 1.3rem;
  text-align: center;
  margin: 0;
  padding: 10px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .phuket-card-overlay p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 600px) and (max-width: 767px) {
  .phuket-card-overlay p {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 599px) {
  .phuket-card-overlay p {
    font-size: 0.37rem;
  }
}

/* Training Camp */
/* Define main colors */
:root {
  --main-color: #ffb505;
  --secondary-color: #006e90;
}

/* Container */
.training-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px; /* Add padding to container */
  box-sizing: border-box; /* Include padding in width */
}

/* Rows */
.training-camp-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  padding: 20px;
  margin-bottom: 20px; /* Add margin bottom for spacing between rows */
  width: 100%; /* Make rows full width */
  box-sizing: border-box; /* Include padding in width */
}

/* Dark section */
.dark {
  background-color: var(--secondary-color);
  color: #fff;
}

/* Light section */
.light {
  background-color: #f4f4f4;
  color: #333;
}

/* Columns */
.training-camp-column {
  width: 100%;
  box-sizing: border-box; /* Include padding in width */
}

/* Image column */
.image-column img {
  max-width: 100%; /* Adjusted to fit the container */
  height: auto;
}

/* Text column */
.text-column {
  padding: 0 20px; /* Add horizontal padding */
  text-align: justify;
}

/* Highlight the first letter */
.training-camp-column.text-column p::first-letter {
  font-size: 150%; /* Make the first letter bigger */
  color: var(--main-color); /* Apply main color to the first letter */
}

.training-camp-column.text-column h3 {
  font-family: "Helvetica";
  font-size: 1.4rem;
  color: var(--main-color);
  padding-bottom: 20px;
}


/* Style for the unordered list */
.training-camp-column.text-column ul {
  list-style-type: disc; /* Use bullets for list items */
  padding-left: 20px; /* Add left padding to create space */
}

/* Style for list items */
.training-camp-column.text-column ul li {
  margin-bottom: 10px; /* Add bottom margin to separate list items */
}

/* Responsive adjustments */
@media screen and (max-width: 767px) {
  .training-camp-row {
    flex-direction: column;
  }
  
  .training-camp-column {
    width: 100%;
    margin-bottom: 20px;
  }
}



/* About section */
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.grid-item {
  text-align: center;
  padding-right: 40px;
  padding-left: 40px
}

.round-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.about, 
.form-title {
  color: #006e90;
  margin-top: 10vh;
  font-size: 48px;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
  padding-bottom: 12px;
}

.about-title {
  padding-top: 20px;
  font-family: 'Open Sans';
  color: #555;
}

.about-content {
  color: #777;
}


.about::after,
.form-title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px; /* Adjust the distance of the line from the text */
  transform: translateX(-50%);
  width: 50px; /* Adjust the width of the line */
  height: 2px; /* Adjust the thickness of the line */
  background-color: #555; /* Adjust the color of the line */
}

@media screen and (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1366px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Social Media */
.social-media-container {
  text-align: center;
  background-color: #1a1a1a;
}

.social-media-text {
  font-family: 'Playfair Display';
  font-size: 32px; /* Adjust size as needed */
  color: white; /* or powder */
  margin-bottom: 20px; /* Add some spacing between text and icons */
  padding-top: 38px;
}

.social-media-icons {
  background-color: #1a1a1a; /* Darker blue or black */
  padding: 10px;
  padding-bottom: 38px;
  border-radius: 10px; /* Add some rounded corners */
}

.social-media-icons a {
  color: #f0f0f0; /* Set icon color to white */
  margin-right: 30px; /* Add some spacing between icons */
  transition: opacity 0.3s ease-in-out;
}

.social-media-icons a:hover {
  color: #00c3ff;
  opacity: 0.9;
}

.social-media-icons a:last-child {
  margin-right: 0; /* Remove margin from last icon */
}


/* Tours */
.tours-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.quote {
  font-style: italic;
  margin-bottom: 20px;
}
.tours-section {
  padding: 50px 0; /* Adjust padding as needed */
}

/* Highlighted quote */
.highlighted-quote {
  background-color: #fff; /* Background color for the highlighted quote */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for the highlighted quote */
  text-align: center;
  margin-bottom: 30px; /* Adjust margin as needed */
}

@media  screen and (min-width: 768px) {
  .highlighted-quote {
    display: none;
    margin-bottom: 0;
  }
}

.quote {
  font-size: 1.5rem; /* Adjust font size as needed */
  color: #006e90; /* Text color for the quote */
}

/* Light section */
.section-light {
  text-align: justify;
  background-color: #f9f9f9; /* Background color for the light section */
  padding: 50px;
  border-radius: 5px;
  margin-bottom: 30px; /* Adjust margin as needed */
}

.section-light h2 {
  color: #006e90; /* Text color for section heading */
}

/* Dark section */
.section-dark {
  text-align: justify;
  background-color: #006e90; /* Background color for the dark section */
  color: #fff; /* Text color for the dark section */
  padding: 50px;
  border-radius: 5px;
  margin-bottom: 30px; /* Adjust margin as needed */
}

.section-dark h2 {
  color: #ffb505; /* Text color for section heading */
}

.section-dark p {
  color: #ccc; /* Text color for section paragraph */
}

/* Forms */
.forms-section {
  background-color: #fcfdfa;
}

.forms-container {
  max-width: 600px;
  margin: 0 auto 100px; /* Center the container horizontally and add margin at the bottom */
}

@media screen and (max-width: 768px) {
  .forms-container {
    max-width: 80%;
    margin-bottom: 50px;
  }
}

.highlight-email,
.form-note {
  margin-bottom: 40px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.highlight-email {
  color:#006e90;
}

.form-input {
  display: block;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border-color: #888;
  background-color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
}

.form-input::placeholder {
  color: #777;
}

.form-label {
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.form-submit {
  width: 40%;
  padding: 20px;
  background-color: #333;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-top: 20px;
  margin: 20px auto 0 auto;
}

.form-submit:hover {
  background-color: #006e90;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 0.5s;
}


.form-error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* Form pop up*/
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  position: relative; /* Added position relative */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}


.close:hover {
  color: var(--main-color);
}
/* Footer */
.footer-container {
  background-color: #070707;
  color: #fcfdfa;
  position: fixed;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
}

.tat-credentials {
  position: fixed;
  font-size: 12px;
  left: 0;
  text-align: left;
  padding-top: 1%;
}

.hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  .footer-container p {
    font-size: 8px;
  }

  .tat-credentials{
    font-size: 6px;
    padding: 8px 0 0 2px;
  }
}

/* Not Found */
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 150px;
  width: 150px;
  margin: 50px 0;
}
.wave {
  position: absolute;
  border-radius: 50%;
  animation: wave 1.5s linear infinite;
}
.wave:nth-child(1) {
  height: 90%;
  width: 90%;
  border: 10px solid #f1c40f;
}
.wave:nth-child(2) {
  height: 80%;
  width: 80%;
  border: 10px solid #e67e22;
  animation-delay: 0.5s;
}
.wave:nth-child(3) {
  height: 70%;
  width: 70%;
  border: 10px solid #e74c3c;
  animation-delay: 1s;
}
@keyframes wave {
  0% {
      transform: scale(0.8);
      opacity: 1;
 }
  50% {
      transform: scale(1.2);
      opacity: 0.5;
 }
  100% {
      transform: scale(0.8);
      opacity: 1;
 }
}

/* Wedding */
.wedding-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
  margin: 100px 20px 0 20px;
}

.image-container {
  flex: 1;
}

.image-container img {
  width: 100%;
  height: auto;
}

.content-container {
  flex: 0.618;
  padding: 20px;
  text-align: justify;
  
}

h1 {
  font-size: 2rem;
  margin-top: 0;
}

.blockquote {
  position: relative;
  font-size: 1.2rem;
  font-style: italic;
  margin: 20px 0;
  padding: 20px;
}

.blockquote::before {
  content: open-quote;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 4rem;
  color: #ffb505; /* Quotation mark color */
}

.blockquote::after {
  content: '';
  display: block;
  width: 60px; /* Adjust width as needed */
  height: 2px; /* Adjust height as needed */
  background-color: #ffb505; /* Line color */
  margin: 20px auto 0;
}

.blockquote p {
  margin-bottom: 10px;
}

.blockquote cite {
  display: block;
  text-align: right;
  font-style: normal;
  color: #777; /* Author color */
}

p {
  margin-bottom: 20px;
}

.scroll-button {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #ffb505;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .wedding-container {
    flex-direction: column;
    margin: 20px 20px 0 20px;
  }
  
  .image-container {
    order: 1;
  }

  .content-container {
    order: 2;
    padding: 10px;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  .scroll-button {
    padding: 8px 16px;
  }
}

.wedding-services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  margin-bottom: 60px;
}

.wedding-column {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  box-shadow: #d9d9d9 0px 7px 29px 0px;}

.column-title {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  color: #006e90;
}

.service-list {
  list-style-type: disc;
  padding: 0;
}

.service-list li {
  margin-bottom: 10px;
}

/* Home Navigation */
.home-navigation {
  position: fixed;
  top: 40px;
  right: 30px;
  z-index: 1000; /* Ensure the home button stays on top of other content */
  color: #ffb505;
  font-size: 24px;
  text-decoration: none;
  transition: opacity 0.3s;
}

.home-navigation:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .home-navigation {
    display: none; /* Hide the back home on screens larger than tablets */
  }
}

.notes-container {
  margin: 0px 20px 0px 20px;
}

.notes {
  font-weight: bold;
  color: #006e90;
  font-size: 1.2rem;
}

.notes + ul {
  margin-top: 0.5rem;
  padding-left: 1rem;
}

.contact-container {
  margin-bottom: -15vh;
}

@media screen and (max-width: 786px) {
  .contact-container {
    margin-bottom: -5vh;
  }
}

.contact-info {
  margin-top: 1.5rem;
  font-size: 0.9rem;
}

.contact-info strong {
  font-weight: bold;
  color: #006e90;
  display: inline-block;
  width: 70px;
}

.logo-container {
  flex-shrink: 0; /* Prevent logo from shrinking */
}

@media screen and (max-width: 1024px) {
  .logo-container {
    padding-bottom: 9%;
  }
}
.tours-logo {
  height: auto;
  width: 100px; /* Adjust width as needed */
}

/* Mansory */

h6, p {
  font-family: "Playfair Display";
}

.c-heroImageGrid {
	position: relative;
	overflow: hidden;
	height: 50vw;
	background: #fff;
	margin: 95px 1% -20px 1%;
}

@media screen and (max-width: 768px) {
  .c-heroImageGrid {
    margin: 1%;
  }
}
.c-heroImageGrid .tours-container {
	display: flex;
	align-items: flex-end;
	position: absolute;
	top: -20vw;
	left: -8vw;
	transform: rotateZ(45deg);
	height: 100vw;
	width: 107vw;
}

.c-heroImageGrid .tours-container .column {
	overflow: hidden;
	position: relative;
}

.c-heroImageGrid .tours-container .column:nth-of-type(1) {
	border-right: 8px solid white;
	width: 25%;
	height: 66vw;
}

.c-heroImageGrid .tours-container .column:nth-of-type(1):hover {
	opacity: 0.75;
	cursor: pointer;
}

.c-heroImageGrid .tours-container .column:nth-of-type(1):before {
	position: absolute;
	top: 8.4vw;
	left: 8.1vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	width: 37vw;
	height: 38vw;
	background-image: url("./chalimero-pics/phuket/02.jpg");
}


.c-heroImageGrid .tours-container .column:nth-of-type(2) {
	display: flex;
	flex-direction: column-reverse;
	width: 75%;
	height: 109.1vw;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row {
	border-top: 8px solid #fff;
}


.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(1) {
	position: relative;
	overflow: hidden;
	height: 50vw;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(1):hover {
	opacity: 0.75;
	cursor: pointer;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(1):before {
	position: absolute;
	top: -11.1vw;
	left: -15.3vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	width: 69vw;
	height: 38vw;
	background-image: url("./chalimero-pics/tours/13.jpg");
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) {
	display: flex;
	height: 40.5vw;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(3) {
	border-top: none;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) {
	overflow: hidden;
	width: 30%;
	background-color: #d8d8d8;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text {
	width: 100%;
	height: 100%;
	overflow: visible;
	transform: rotateZ(-45deg);
	position: relative;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text h6 {
	position: absolute;
	color: #fff;
	font-size: 1.35vw;
  font-style: italic;
	width: 20vw;
	top: 24vw;
	left: 1vw;
	line-height: 1.55vw;
}

@media screen and (max-width: 768px) {
  .c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text h6 {
  display: none;
  }
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(1) .text p {
	text-align: right;
	position: absolute;
	color: #fff;
	font-size: 0.9vw;
	width: 18vw;
	top: 23.5vw;
	left: -3.5vw;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(2) {
	position: relative;
	width: 70%;
	border-left: 8px solid white;
	overflow: hidden;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(2):hover {
	opacity: 0.75;
	cursor: pointer;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(2) div:nth-of-type(2):before {
	position: absolute;
	top: -8vw;
	left: -15vw;
	width: 67vw;
	height: 50vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	background-image: url("./chalimero-pics/phuket/12.jpg");
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(3) {
	position: relative;
	height: 18.6vw;
	overflow: hidden;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(3):hover {
	opacity: 0.75;
	cursor: pointer;
}

.c-heroImageGrid .tours-container .column:nth-of-type(2) .row:nth-of-type(3):before {
	position: absolute;
	left: 27vw;
	width: 33vw;
	height: 29vw;
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotateZ(-45deg);
	content: "";
	background-image: url("./chalimero-pics/phuket/01.jpg");
}
